.popover-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #fff;
  z-index: 10;
  border-radius: 0 5rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
}

.popover-close-btn {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 50px;
  height: 50px;
  cursor: pointer;
  color: #000
}

.popover-close-btn:hover {
  color: red;
}

.popover-content {
  width: 90%;
  height: 85%;
  overflow-y: auto;
}

.popover-content::-webkit-scrollbar {
  background-color: #fff;
  border-radius: 1rem;
}

.popover-content::-webkit-scrollbar-thumb {
  background-color: #e3dece;
  border-radius: 0.5rem;
}

.popover-content h3 {
  color: #7dd87d;
}

.popover-content a {
  text-decoration: none;
  color: #0049ff;
  cursor: pointer;
}