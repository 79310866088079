/* Global/body CSS */
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto-Thin, sans-serif;

  /* Disable select*/
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 2) {
  body {
    font-family: Roboto-Light, sans-serif;
  }
}

/* Custom emoji CSS animation */

.emoji-wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
  margin-left: 0.5rem;
}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
  20% { transform: rotate(-10.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

.emoji-bounce {
  animation-name: bounce-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  display: inline-block;
  margin-left: 0.5rem;
}

@keyframes bounce-animation {
  0% { transform: translateY(0px)}
  10% { transform: translateY(0px)}
  20% { transform: translateY(-15px)}
  30% { transform: translateY(0px)}
  40% { transform: translateY(-10px)}
  50% { transform: translateY(0px)}
  60% { transform: translateY(-10px)}
  1000% { transform: translateY(10px)}
}