#project-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#project-container h1 {
  margin: 0;
}

#project-container h3 {
  margin-top: 0;
}

#project-carousel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.nav-chevron {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

#current-project-focus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.project-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.project-item > img {
  height: 100%;
  width: 100%;
}

.project-description {
  padding-top: 1rem;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 933px) {
  #project-container {
    justify-content: flex-start;
  }
}