#contact-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

#contact-container i {
  color: #0049ff;
}

#social-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 1.5rem;
  width: 100%;
  flex-wrap: wrap;
}

#social-links a {
  width: 5rem !important;
  height: 5rem !important;
  margin: 1.5rem;
}

.social-icon {
  margin: 0 1.5rem;
}

#contact-info-prompt {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

#contact-info-prompt div {
  position: fixed;
  opacity: 0;
  font-size: 2.5rem;
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 2) {
  .social-icon {
    margin: 0;
  }

  #social-links {
    width: 90%;
  }
}