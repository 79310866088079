#profile-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	overflow-y: hidden;
}

#profile {
	opacity: 0;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-direction: column;
	width: 50vw;
	margin-bottom: 5vh; /* Offset for footer to center this better */
}

#profile-pic-card {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 1rem;
	width: 100%;
	font-size: 2rem;
}

#profile-pic-card > img {
	border: 5px solid white;
	border-radius: 1rem;
	margin-bottom: 1rem;
	margin-right: 1.5rem;
}

.profile-text {
	opacity: 0;
}

.profile-text a {
	text-decoration: none;
	color: #0049ff;
	cursor: pointer;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 2) {
	#profile {
		width: 90%;
		font-size: 1.5rem;
	}
}

/* TODO: Fix landscape */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 933px)
and (-webkit-min-device-pixel-ratio: 3) {
	#profile {
		width: 100%;
		overflow-y: auto;
	}
}